body {
  margin: 0;
  padding: 0;
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.top-container {
  width: 89%;
  border: 2px solid #333;
  background-color: #1f1f1f;
  padding: 20px;
  margin-top: 30px;
  margin-bottom: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.top-vpns-container {
  width: 90%;
  height: auto;
  border: 2px solid #333;
  background-color: #1f1f1f;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.top-vpns-container a {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-decoration: underline;
}

.top-vpns-container:hover {
  transform: translateX(-5px);
}

.right-align {
  display: inline-block;
  text-align: right;
  width: 80%;
}

.left-align {
  display: inline-block;
  text-align: left;
  width: 100%;
}

@media (max-width: 767px) {
  .top-container {
    width: auto;
  }
}

.satyrn-container {
  width: 75%;
  background-color: #1f1f1f;
  padding: 20px;
  margin-top: 10px;
  border-radius: 8px;
}

@media (max-width: 767px) {
  .satyrn-container {
    width: 100%;
  }
}

.satyrn-container :hover {
  background-color: #444;
}

.satyrn-container h3:hover {
  text-decoration: underline;
}

.satyrn-container h3 {
  font-size: 1.5em;
  color: #fff;
  margin-bottom: 10px;
  text-align: center;
  margin: auto;
}

.satyrn-container h6 {
  color: #fff;
  text-align: center;
  margin: auto;
}

.satyrn-container p {
  margin: 0;
  font-size: 1.1em;
  color: #ccc;
  line-height: 1.5;
  text-align: center;
}

.satyrn-container p a {
  color: #66BFFF;
  text-decoration: none;
  transition: color 0.3s;
}

.satyrn-container p a:hover {
  color: #7AA4FF;
  text-decoration: underline;
}

.tier-list {
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  min-height: 100vh;
  background: linear-gradient(45deg, #121212, #292929, #1f1f1f, #292929);
  background-size: 400% 400%;
  color: #fff;
  animation: background-animation 15s linear infinite;
}

@media (max-width: 767px) {
  .tier-list {
    width: auto;
  }
}

@keyframes background-animation {
  0% {
    background-position: 0% 0%;
  }
  12.5% {
    background-position: 50% 0%;
  }
  25% {
    background-position: 100% 25%;
  }
  37.5% {
    background-position: 75% 50%;
  }
  50% {
    background-position: 100% 100%;
  }
  62.5% {
    background-position: 50% 75%;
  }
  75% {
    background-position: 25% 100%;
  }
  87.5% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 0% 0%;
  }
}

body {
    background-color: #1f1f1f;
}

.privacy-policy-title {
    font-size: 30px;
    background-color: #1f1f1f;
    color: #fff;
    margin-top: 20px;
    color: #ffffff;
}

@media (max-width: 767px) {
  .privacy-policy-title {
    width: auto;
  }
}

.privacy-policy-title-container {
  border-radius: 8px;
  border: 2px solid #333;
  text-align: center;
  margin: auto;
  margin-top: 30px;
  margin-bottom: 30px;
  max-width: 95%;
  padding: 20px;
}

@media (max-width: 767px) {
  .privacy-policy-title-container {
    width: 90%;
  }
}

.privacy-policy {
    display: flex;
    border: 2px solid #333;
    border-radius: 8px;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 30px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 95%;
    padding: 20px;
    background-color: #1f1f1f;
    color: #fff;
  }

  @media (max-width: 767px) {
    .privacy-policy {
      width: 90%;
    }
  }
  
  .privacy-policy h1,
  .privacy-policy h2,
  .privacy-policy h3 {
    font-size: 30px;
    margin-top: 20px;
    max-width: 75%;
    text-align: center;
  }
  
  .privacy-policy p {
    max-width: 75%;
    font-size: 22px;
    margin-top: 10px;
    line-height: 1.5;
    text-align: center;
  } 

  @keyframes background-animation {
    0% {
      background-position: 0% 0%;
    }
    12.5% {
      background-position: 50% 0%;
    }
    25% {
      background-position: 100% 25%;
    }
    37.5% {
      background-position: 75% 50%;
    }
    50% {
      background-position: 100% 100%;
    }
    62.5% {
      background-position: 50% 75%;
    }
    75% {
      background-position: 25% 100%;
    }
    87.5% {
      background-position: 0% 50%;
    }
    100% {
      background-position: 0% 0%;
    }
  }
.about-page {
  flex: 1;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #1f1f1f;
  color: #ffffff;
  overflow-x: hidden;
  overflow-y: auto;
  }

  @media (max-width: 767px) {
    .about-page {
      width: auto;
    }
  }

.about-container {
  max-width: 100%;
  border: 2px solid #333;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-top: 30px;
  margin-bottom: 30px;
  }
  
  .about-container h2 {
    font-size: 32px;
    max-width: 90%;
    margin-top: 20px;
    color: #ffffff;
  }
  
  .about-container p {
    font-size: 18px;
    margin-top: 10px;
    line-height: 1.5;
    max-width: 80%;
    text-align: center;
    color: #dddddd;
  }

  @media (max-width: 767px) {
    .about-container {
      width: 100%;
    }
  }

  @keyframes background-animation {
    0% {
      background-position: 0% 0%;
    }
    12.5% {
      background-position: 50% 0%;
    }
    25% {
      background-position: 100% 25%;
    }
    37.5% {
      background-position: 75% 50%;
    }
    50% {
      background-position: 100% 100%;
    }
    62.5% {
      background-position: 50% 75%;
    }
    75% {
      background-position: 25% 100%;
    }
    87.5% {
      background-position: 0% 50%;
    }
    100% {
      background-position: 0% 0%;
    }
  }